
.container {
    border-radius: 5px;
    box-shadow: 4px 4px 8px darkgrey; // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 25px 50px 0 rgba(0, 0, 0, 0.1);
    
}

.locationContainer {   
    background-color: rgba(0, 0, 0, 0.12);
    border: solid rgba(0,0,0, 0.012) 3px;
}

.tableSidePadding {
    background-color: rgb(197, 196, 196);
}

.border {
    border: solid rgba(0, 0, 0, 0.12) 3px;
}
